<template>
  <v-card elevation="6" v-if="tattooer" id="tattooer-card">
    <v-card-title class="py-3">
      <h2 style="text-transform: uppercase; font-weight: normal">
        {{ $tc("artist") }}
        <span
          class="ml-n2 ml-md-3 login_error--text"
          v-if="!active && $store.getters['auth/isLoggedIn']"
        >
          {{ $t("tattooers.states.unactive") }}
        </span>
      </h2>
      <v-btn
        v-if="
          appointment &&
            appointment.commission_id !== tattooer.id &&
            relations.length &&
            relations[0].comisions &&
            relations[0].comisions.type !== null &&
            active
        "
        height="30px"
        outlined
        class="ml-2"
        @click="$emit('calculate_commissions')"
      >
        {{ $t("recalculate_comisions") }}
      </v-btn>
      <h5 v-if="extra_tattooers && extra_tattooers.length > 1">
        {{
          $tc("extra_tattooers.count", extra_tattooers.length, {
            count: extra_tattooers.length,
          })
        }}
      </h5>
      <AddExtraTattooer
        :absolute="true"
        v-if="$store.getters['auth/isLoggedIn'] && appointment"
        :appointment="appointment"
        @update="$emit('update')"
      ></AddExtraTattooer>
      <v-btn
        icon
        v-if="
          $store.getters['auth/isLoggedIn'] &&
            share_info &&
            $route.name === 'appointmentView' &&
            extra_list.length == 1
        "
        style="position: absolute; right: 60px"
        small
        @click="$emit('asignate', tattooer)"
      >
        <v-icon color="primary"> mdi-repeat-variant </v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="$store.getters['auth/isLoggedIn'] && share_info"
        @click="
          $router.push({ name: 'tattooerEdit', params: { id: tattooer.id } })
        "
        style="position: absolute; right: 25px"
        small
      >
        <v-icon small color="primary"> $edit </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <commissions
      v-if="t_commission"
      :disable="true"
      :tattooer="t_commission"
      :view="true"
      :list="true"
      ref="commissions"
    />
    <template v-for="(t, i) in extra_list">
      <v-simple-table :key="t.id">
        <tr>
          <td>{{ $t("first_name") }}</td>
          <td>
            {{ share_info && t.user.first_name ? t.user.first_name : "-" }}
            {{ share_info && t.user.last_name ? t.user.last_name : "" }}
          </td>
        </tr>

        <tr v-if="$store.getters['auth/isStudio']">
          <td>{{ $t("alias") }}</td>
          <td>
            {{ share_info && t.user.first_name ? t.user.first_name : "-" }}
          </td>
        </tr>
        <tr v-if="$store.getters['auth/isLoggedIn']">
          <td>{{ $t("consent-form.email") }}</td>
          <td class="no-warp">
            {{ t.user.email || "-" }}
          </td>
        </tr>
        <tr v-if="$store.getters['auth/isLoggedIn']">
          <td>{{ $t("phone") }}</td>
          <td>
            {{
              relations.length && relations[0].status === "confirmed"
                ? t.user.phone
                  ? t.user.phone
                  : "-"
                : t.user.phone
                ? t.user.phone
                : "-"
            }}
            <WhatsApp :phone_number="t.user.phone" />
          </td>
        </tr>
        <tr
          v-if="
            $store.getters['auth/isStudio'] &&
              $store.getters['auth/getSetting']('comisions_active') === 1
          "
        >
          <td>{{ $t("expenses.commissions") }}</td>
          <td>
            <PayPendingTattooer
              :appointment="appointment"
              :other="false"
              v-if="token_payment && t.id === tattooer.id"
              :pay="token_payment > 0"
              @update="
                $emit('update');
                fetchCommision();
              "
            />
            <v-icon
              v-else-if="$route.name === 'appointmentView' && tp_total"
              color="success"
              class="mr-2"
            >
              mdi-check
            </v-icon>

            <span v-if="token_payment && t.id === tattooer.id" class="pr-2">
              {{ $n(Math.abs(token_payment), "currency") }}
            </span>
            <span
              v-else-if="
                $route.name === 'appointmentView' && t.id === tattooer.id
              "
              class="pr-2"
            >
              {{ $n(tp_total, "currency") }}
            </span>

            <v-btn
              v-if="
                relations.length &&
                  relations[0].comisions &&
                  relations[0].comisions.type !== null
              "
              class="ml-n3"
              style="height: 15px"
              @click="
                t_commission = t;
                $refs.commissions.fetchCommissions();
                $refs.commissions.dialog = true;
              "
              icon
            >
              <v-icon small>$eye</v-icon>
            </v-btn>
            <span v-else-if="!token_payment"> - </span>
          </td>
        </tr>
        <tr
          v-if="
            $store.getters['auth/isStudio'] &&
              $store.getters['auth/getSetting']('comisions_active') === 1
          "
        >
          <td>{{ $t("expenses.other_commissions") }}</td>
          <td>
            <PayPendingTattooer
              :appointment="appointment"
              :other="true"
              v-if="other_token_payment && t.id === tattooer.id"
              @update="
                $emit('update');
                fetchCommision();
              "
            />
            <v-icon
              v-else-if="$route.name === 'appointmentView' && tp_total"
              color="success"
              class="mr-2"
            >
              mdi-check
            </v-icon>

            <span
              v-if="other_token_payment && t.id === tattooer.id"
              class="pr-2"
            >
              {{ $n(other_token_payment, "currency") }}
            </span>
            <span
              v-else-if="
                $route.name === 'appointmentView' && t.id === tattooer.id
              "
              class="pr-2"
            >
              {{ $n(other_tp_total, "currency") }}
            </span>

            <v-btn
              v-if="
                relations.length &&
                  relations[0].comisions &&
                  relations[0].comisions.type !== null
              "
              class="ml-n3"
              style="height: 15px"
              @click="
                t_commission = t;
                $refs.commissions.fetchCommissions();
                $refs.commissions.dialog = true;
              "
              icon
            >
              <v-icon small>$eye</v-icon>
            </v-btn>
            <span v-else-if="!other_token_payment"> - </span>
          </td>
        </tr>
        <tr v-if="$store.getters['auth/isStudio']">
          <td style="vertical-align: baseline">
            {{ $t("inventorys.description") }}
          </td>
          <td>
            <v-textarea
              v-bind:class="[{ disabled: !edit_list[i] }]"
              outlined
              v-model="t.description"
              hide-details
              :rows="t.description && t.description.length > 1000 ? 3 : 2"
              style="border-radius: 10px"
              @blur="update(t, i)"
              @click="editDescription(i)"
            ></v-textarea>
          </td>
        </tr>
        <template v-if="show[i]">
          <tr v-if="$store.getters['auth/isLoggedIn']">
            <td>{{ $t("tattooers.birthdate") }}</td>
            <td>
              {{
                share_info && t.birthdate
                  ? $d(new Date(t.birthdate), "date2digits")
                  : "-"
              }}
            </td>
          </tr>
          <tr v-if="$store.getters['auth/isLoggedIn']">
            <td>{{ $t("tattooers.nationality") }}</td>
            <td>
              {{
                share_info && t.nationality && t.nationality !== "null"
                  ? t.nationality
                  : "-"
              }}
            </td>
          </tr>
          <tr v-if="$store.getters['auth/isLogged']">
            <td>{{ $t("artists") }}</td>
            <td>
              <span v-for="(types, i) in t.type_artist" :key="i">
                {{ $tc(types.name) }}
                <span v-if="t.type_artist.length - 1 !== i">/</span>
              </span>
            </td>
          </tr>

          <tr v-if="$store.getters['auth/isStudio']">
            <td>{{ $t("files") }}</td>
            <td>
              <v-btn class="ml-n3" style="height: 15px" icon @click="goToFiles">
                <v-icon small>$eye</v-icon>
              </v-btn>
            </td>
          </tr>

          <tr v-if="$store.getters['auth/isStudio']">
            <td>{{ $t("vacations.title") }}</td>
            <td>
              <CloseDaysTattooer :tattooer="t"></CloseDaysTattooer>
            </td>
          </tr>

          <tr v-if="$store.getters['auth/isStudio']">
            <td>{{ $t("tattooers.tattoo_type") }}</td>
            <td>
              <v-dialog width="750" v-model="dialog_tattoo_type">
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-n3" style="height: 15px" v-on="on" icon>
                    <v-icon small>$eye</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    {{ $t("tattooers.tattoo_type") }}
                  </v-card-title>

                  <div class="close">
                    <v-btn icon @click="dialog_tattoo_type = false" small>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-for="tt in t.tattoo_type"
                        :key="tt.id"
                        cols="12"
                        md="6"
                      >
                        <v-card>
                          <v-card-title>
                            {{ tt.name }}
                          </v-card-title>
                          <v-card-text>
                            {{ tt.description }}
                          </v-card-text>
                          <v-img height="150px" :src="tt.src"></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </td>
          </tr>
          <tr v-if="$store.getters['auth/isStudio'] && timetable">
            <td>{{ $t("timetable", { name: "" }) }}</td>
            <td>
              <v-dialog v-model="dialog" width="750">
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-n3" icon v-on="on" style="height: 15px">
                    <v-icon small>$eye</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    style="
                      text-decoration: underline;
                      text-transform: uppercase;
                    "
                  >
                    {{ $t("timetable", { name: $tc("artist") }) }}
                  </v-card-title>
                  <div class="close">
                    <v-btn icon @click="dialog = false" small>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-simple-table id="horario_tattooer">
                    <thead>
                      <tr>
                        <th
                          v-for="n in (1, 7)"
                          :min-width="n === 3 ? 125 : 100"
                          :max-width="n === 3 ? 125 : 100"
                          :width="n === 3 ? 125 : 100"
                          :key="n"
                        >
                          {{ $t("week_days")[n] }}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="max">
                      <tr v-for="n in (1, max)" :key="n">
                        <td
                          v-for="m in (1, 7)"
                          :key="m"
                          :min-width="m === 3 ? 125 : 100"
                          :width="m === 3 ? 125 : 100"
                          :max-width="m === 3 ? 125 : 100"
                        >
                          <template v-if="timetable[m - 1][n - 1]">
                            <p>{{ timetable[m - 1][n - 1][0] }}</p>
                            <p>{{ timetable[m - 1][n - 1][1] }}</p>
                          </template>
                          <span v-else>-</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-dialog>
            </td>
          </tr>

          <slot></slot>
        </template>

        <tr v-if="$store.getters['auth/isLoggedIn']">
          <td colspan="2">
            <a @click="change(i)">
              <u>{{ show[i] ? $t("see_less") : $t("see_more") }}</u>
            </a>

            <a
              class="ml-3"
              v-if="extra_list.length > 1 && $store.getters['auth/isLoggedIn']"
              @click="changeTattooer(t)"
            >
              <u>Cambiar</u>
            </a>
            <a
              class="ml-3"
              v-if="extra_list.length > 1 && $store.getters['auth/isLoggedIn']"
              @click="del(t)"
            >
              <u>{{ $t("delete", { name: "" }) }}</u>
            </a>
          </td>
        </tr>
      </v-simple-table>

      <v-divider :key="`divider-${t.id}`" v-if="i < extra_list.length - 1" />
    </template>

    <v-card-actions> </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-title class="py-3">
      <h2 style="text-transform: uppercase; font-weight: normal">
        {{ $tc("artist") }}
      </h2>
      <v-btn
        icon
        v-if="
          $store.getters['auth/isLoggedIn'] && $store.getters['auth/isStudio']
        "
        style="position: absolute; right: 25px"
        small
        @click="$emit('asignate')"
      >
        <v-icon large> mdi-plus-circle </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-simple-table>
      <tr>
        <td class="text-center">
          <h2 style="color: red !important">
            {{ $t("tattooers.not_assigned") }}
          </h2>
        </td>
      </tr>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooerCard",
  props: ["tattooer", "extra_tattooers", "appointment"],
  data() {
    return {
      relations: [],
      active: false,
      loading: false,
      dialog: false,
      show: [],
      edit_list: [],
      t_commission: undefined,
      token_payment: 0,
      tp_total: 0,
      other_token_payment: 0,
      other_tp_total: 0,
      dialog_tattoo_type: false,
    };
  },
  components: {
    CloseDaysTattooer: () =>
      import("@/components/user/settings/profile/CloseDaysTattooer"),
    Commissions: () => import("@/components/tattooer/Commissions"),
    ExtraTattooers: () => import("@/components/tattooer/ExtraTattooers"),
    AddExtraTattooer: () => import("@/components/tattooer/AddExtraTattooer"),
    PayPendingTattooer: () =>
      import("@/components/appointments/PayPendingTattooer"),
  },
  watch: {
    extra_list: {
      handler() {
        let a = [];
        let e = [];
        this.extra_list.forEach((element) => {
          a.push(false);
          e.push(false);
        });
        this.show = a;
        this.edit_list = e;
      },
      deep: true,
    },
    tattooer: {
      handler() {
        this.fetchTattooerStudioRelations();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchTattooerStudioRelations();

    let a = [];
    let e = [];
    this.extra_list.forEach((element, j) => {
      e.push(false);
    });
    this.show = a;
    this.edit_list = e;

    if (this.extra_list.length) this.t_commission = this.extra_list[0];
    else this.t_commission = this.tattooer;
    console.log("t_commission", this.t_commission);
    this.fetchCommision();
  },
  methods: {
    ...mapActions("expenses", ["getExpenses"]),
    fetchCommision() {
      console.log("id", this.$route.params.id);
      if (this.$route.name === "appointmentView")
        this.getExpenses({
          filters: { appointment_id: this.$route.params.id },
        }).then((result) => {
          let t = 0,
            tt = 0,
            ot = 0,
            ott = 0;
          result.data.forEach((e) => {
            if (e.conceptos.startsWith("Comision artista -")) {
              console.log(e);
              if (e.appointment_date_id) {
                if (e.pending == true) t -= e.cantidad;
                tt -= e.cantidad;
              } else {
                if (e.pending == true) ot -= e.cantidad;
                ott -= e.cantidad;
              }
            }
          });
          this.token_payment = t;
          this.tp_total = tt;
          this.other_token_payment = ot;
          this.other_tp_total = ott;
        });
    },

    change(i) {
      let a = [];
      this.extra_list.forEach((element, j) => {
        if (j === i) a.push(!this.show[j]);
        else a.push(this.show[j]);
      });
      this.show = a;
    },
    editDescription(i) {
      let e = [];
      this.extra_list.forEach((element, j) => {
        if (j === i) e.push(!this.edit_list[j]);
        else e.push(this.edit_list[j]);
      });
      this.edit_list = e;
    },
    goToFiles() {
      let name = `${this.tattooer.user.first_name}`;
      if (this.tattooer.user.last_name) {
        name += ` ${this.tattooer.user.last_name}`;
      }
      this.$router.push({
        name: "filesView",
        params: {
          sub_route: name,
        },
      });
    },
    ...mapActions("tattooers", [
      "getTattooer",
      "getTattooerStudioRelations",
      "editTattooer",
    ]),

    changeTattooer(t) {
      this.$emit("asignate", t);
    },
    ...mapActions("appointments", ["removeTattooer"]),
    del(t) {
      console.log("del", t.id);
      this.$confirm(this.$t("delete_customer")).then(() => {
        this.removeTattooer({
          appointment_id: this.appointment.id,
          tattooer_id: t.id,
        }).then(() => {
          this.$emit("update");
        });
      });
    },
    update(t, i) {
      console.log("update ARTIST");
      this.editTattooer({
        tattooer_id: t.id,
        tattooer: t,
      }).then((response) => {
        this.editDescription(i);
      });
    },
    fetchTattooerStudioRelations() {
      this.loading = true;
      console.log("fetch", this.tattooer);
      //SI ARTISTA COMPROVEM
      if (this.tattooer) {
        this.getTattooerStudioRelations({
          tattooer_id: this.tattooer.id,
          studio_id: this.$store.state.auth.user.studio.id,
        }).then((tatStuRel) => {
          //console.log("inside fetchhh");

          console.log("%ca", "color: #e50000", tatStuRel);
          this.relations = tatStuRel;
          let today = new Date().getTime();
          let a = tatStuRel.find((x) => {
            let to = new Date(x.to).getTime();
            let from = new Date(x.from).getTime();
            console.log(from < today, to > today);
            return from <= today && (x.to === null || to > today);
          });
          console.log(a);
          if (a) {
            this.active = true;
          }
          //console.log(this.relations);
          this.loading = false;
        });
      }
      //SINO ARTISTA, SEMPRE ACTIVE
      else {
        this.active = true;
      }
    },
  },
  computed: {
    extra_list() {
      if (this.extra_tattooers && this.extra_tattooers.length)
        return this.extra_tattooers;
      console.log(this.tattooer);
      if (this.tattooer && this.tattooer.user.first_name)
        return [this.tattooer];
      return [];
    },
    share_info() {
      if (!this.$store.getters["auth/isLoggedIn"]) return true;
      let share = false;
      this.relations.forEach((x) => {
        if (x.info_share) share = true;
      });
      return share;
    },
    timetable() {
      return this.relations[0].tattooer_timetable;
    },

    max() {
      if (this.timetable) {
        let lengths = this.timetable.map((t) => t.length);

        return Math.max(...lengths);
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="sass">
.v-data-table:not(#horario_tattooer)
  td
    padding: 3px 8px !important
    &:first-child
      color: var(--v-gris1-base)
      min-width: 100px
      width: 100px
    &:last-child
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 300px
h2
  font-weight: normal
  font-size: 20px
</style>

<style lang="sass">
#horario_tattooer

  table
    min-width: 750px
    td
      padding-top: 10px
      padding-bottom: 10px
      p
        margin-bottom: 10px

        &:last-child
          margin-bottom: 0px
#tattooer-card
  .v-input
    &.disabled
      fieldset
        border-color: rgba(255, 255, 255, 0.5) !important
</style>
